import React, { useMemo } from "react"
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Link } from 'gatsby'
import PropTypes from 'prop-types';

const PREFIX = 'TitleHeader';

const classes = {
  titleHeaderContainer: `${PREFIX}-titleHeaderContainer`,
  additionalContentContainer: `${PREFIX}-additionalContentContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleLink: `${PREFIX}-titleLink`,
  subtitle: `${PREFIX}-subtitle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.titleHeaderContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(2, 0)
    }
  },

  [`& .${classes.additionalContentContainer}`]: {
    flexGrow: 1,
    textAlign: 'right',
    order: -1,
    margin: theme.spacing(1, 0),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column'
  },

  [`& .${classes.titleContainer}`]: {
    flexGrow: 1,
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.titleLink}`]: {
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  [`& .${classes.subtitle}`]: {
    fontSize: 14
  }
}));

const TitleHeader = ({ title, titleLink, subtitle, additionalContent, contentVerticalPosition }) => {

  const alignAdditionalContent = useMemo(() => {
    switch(contentVerticalPosition) {
      case "top":
        return "flex-start"
      case "bottom":
        return "flex-end"
      default:
        return "auto"
    }
  }, [contentVerticalPosition])
  return (
    <Root className={classes.titleHeaderContainer}>
      <div className={classes.titleContainer}>
        {
          titleLink ?
          <Link to={titleLink} className={classes.titleLink}>
            <Typography variant="h1" color="primary" component="h1" className={classes.h1}>
              { title }
            </Typography>
          </Link>
          
          :
          <Typography variant="h1" color="primary" component="h1" className={classes.h1}>
            { title }
          </Typography>
        }
        {
          subtitle && 
          <Typography component="span" color="primary" variant="body1" className={classes.subtitle}>{subtitle}</Typography>
        }
      </div>
      {
        additionalContent &&
        <div 
          className={classes.additionalContentContainer} 
          style={{ alignSelf: alignAdditionalContent }}
        >
          { additionalContent }
        </div>
      }
    </Root>
  );
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  subtitle: PropTypes.string,
  additionalContent: PropTypes.node,
  contentVerticalPosition: PropTypes.oneOf(['bottom', 'top']),
};

export default TitleHeader