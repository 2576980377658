import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';
import { Link } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PREFIX = 'ProductsGroupCard';

const classes = {
  li: `${PREFIX}-li`,
  buttonBase: `${PREFIX}-buttonBase`,
  cardHeader: `${PREFIX}-cardHeader`,
  infoContainer: `${PREFIX}-infoContainer`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  h2: `${PREFIX}-h2`,
  price: `${PREFIX}-price`,
  iconButton: `${PREFIX}-iconButton`,
  icon: `${PREFIX}-icon`
};

const Root = styled('li')((
  {
    theme
  }
) => ({
  [`&.${classes.li}`]: {
    minHeight: 294,
    // '&:last-child': {
    //   marginBottom: 0
    // }
  },

  [`& .${classes.buttonBase}`]: {
    display: 'block', 
    width: '100%',
    position: 'relative'
  },

  [`& .${classes.cardHeader}`]: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    textAlign: 'left',
  },

  [`& .${classes.infoContainer}`]: {
    background: theme.palette.background.transparentGrey,
    padding: theme.spacing(2)
  },

  [`& .${classes.actionsContainer}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: "#E7B607",
  },

  [`& .${classes.h2}`]: {
    margin: 0,
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
    fontFamily: "Krub",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "117.4%",
  },

  [`& .${classes.price}`]: {
    color: theme.palette.common.white,
    fontFamily: "Prompt",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    margin: 0,
  },

  [`& .${classes.iconButton}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.common.white
  }
}));

function ProductsGroupCard({ product, pagePathStart, placeholderImage }) {

  
  return (
    <Root className={classes.li}>
      <article style={{ display: 'grid', height: 294}}>
      <GatsbyImage
        image={getImage(product.image?.localFile || placeholderImage.file)}
        alt={product.name}
        style={{
          gridArea: "1/1",
        }}
        />
          <ButtonBase
          focusRipple
          component={Link}
          to={`${pagePathStart}/${product.slug}`}
          style={{
            gridArea: "1/1",
            position: "relative",
          }}
          classes={{ root: classes.buttonBase}}
          >
            <header className={classes.cardHeader}>
              <div className={classes.infoContainer}>
                {
                  product.price &&
                  <small className={classes.price}>{parse(product.price)}</small>
                }
                <h2 className={classes.h2}>
                  {parse(product.name)}
                </h2>
              </div>
              <div className={classes.actionsContainer}>
                <p className={classes.price}>view modifications</p>
              </div>
            </header>
          </ButtonBase>
      </article>
    </Root>
  );
}

export default ProductsGroupCard;