import React from "react"
import { styled } from '@mui/material/styles';
import { graphql } from "gatsby"
import { Container, Grid, Typography } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import TitleHeader from '@components/TitleHeader'
import SEO from "../components/seo"
import ProductsGroupCard from '@components/ProductsGroupCard'
import DescriptionHeader from "../components/DescriptionHeader"

const PREFIX = 'NewEquipmentIndex';

const classes = {
  container: `${PREFIX}-container`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  list: `${PREFIX}-list`,
  detailedCategoryDescription: `${PREFIX}-detailedCategoryDescription`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },

  [`& .${classes.buttonContainer}`]: {
    textAlign: 'right',
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.list}`]: {
    listStyle: 'none',
    padding: 0,
  },

  [`& .${classes.detailedCategoryDescription}`]: {
    marginTop: theme.spacing(5),
  }
}));

const NewEquipmentIndex = ({
  data,
  pageContext: { pagePathStart },
}) => {
  const { nodes: products } = data.allWpProduct
  const { name, description: categoryDescription, details: { detailedDescription, seoTitle, seoDescription } } = data.wpProductCategory


  const cta = {
    name: name === "Grain Augers" ? "View augers price list" : "View all price lists", 
    url: name === "Grain Augers" ? "/augers-price-list" : "/all-price-lists"
  };

  if (!products.length) {
    return (
      (<>
        <SEO title={seoTitle} description={seoDescription} />
        <Root maxWidth="md" disableGutters>
          <div className={classes.container}>
            <div className={classes.buttonContainer}>
            <Button 
                to={cta.url} 
                variant="contained" 
                color="secondary">
                  { cta.name }
                </Button>
            </div>
            <TitleHeader
              title={name}
              />
            <p>
              No new equipment found.
            </p>
          </div>
        </Root>
      </>)
    );
  }

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      
      <Root maxWidth="md" disableGutters>
        <div className={classes.container}>
          <DescriptionHeader 
            description={categoryDescription} 
            cta={cta}
          />
          <TitleHeader
            title={name}
            contentVerticalPosition="bottom"
          />
          <Grid container spacing={4} component="ul" className={classes.list}>
            {products.map(product => {
                return <Grid key={product.id} item xs={12} sm={6} md={4}>
                    <ProductsGroupCard product={product} pagePathStart={pagePathStart} placeholderImage={data.placeholderImage} />
                  </Grid>
            })}
          </Grid>
          <Grid container className={classes.detailedCategoryDescription}>
            <Grid item xs={12}>
              <Typography
                color='textPrimary'
                component="div"
                variant="body2"
                paragraph
                dangerouslySetInnerHTML={{__html: detailedDescription}}
                ></Typography>
            </Grid>
          </Grid>
        </div>
      </Root>
    </>
  )
}

export default NewEquipmentIndex

export const pageQuery = graphql`query NewEquipmentModels($categoryName: String!) {
  wpProductCategory(name: {eq: $categoryName}) {
    name
    description
    details {
      detailedDescription
      seoTitle
      seoDescription
    }
  }
  placeholderImage: file(relativePath: {eq: "woocommerce-placeholder-1024.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
  allWpProduct(
    sort: {fields: date, order: DESC}
    filter: {status: {eq: "publish"}, productCategories: {nodes: {elemMatch: {name: {eq: $categoryName}}}}, productTypes: {nodes: {elemMatch: {name: {eq: "grouped"}}}}}
  ) {
    nodes {
      id
      name
      productTypes {
        nodes {
          name
        }
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 380, height: 294, quality: 80, layout: CONSTRAINED)
          }
        }
      }
      slug
      ... on WpGroupProduct {
        price
      }
    }
  }
}
`
