import React from "react"
import { styled } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui'
import PropTypes from 'prop-types';

const PREFIX = 'DescriptionHeader';

const classes = {
  topDescriptionContainer: `${PREFIX}-topDescriptionContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.topDescriptionContainer}`]: {
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3)
    }
  },

  [`& .${classes.buttonContainer}`]: {
    textAlign: 'right',
    marginBottom: theme.spacing(1),
  }
}));

const DescriptionHeader = ({ description, subDescription, cta }) => {


  return (
    <StyledGrid container className={classes.topDescriptionContainer}>
      <Grid item xs={12} md={8}>
        <Typography color="textPrimary" paragraph>{description}</Typography>
        { Boolean(subDescription) && <Typography color="textPrimary" paragraph>{subDescription}</Typography> }
      </Grid>
      <Grid item xs={12} md={4}>
        {
        Boolean(cta) && <div className={classes.buttonContainer}>
          <Button 
          to={cta.url} 
          variant="contained" 
          color="secondary">
            { cta.name }
          </Button>
        </div>
        }
      </Grid>
    </StyledGrid>
  );
}

DescriptionHeader.propTypes = {
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string,
  cta: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })
};

export default DescriptionHeader